import React from "react";
import Container from "react-bootstrap/Container";
import GraphQLErrorList from "../components/graphql-error-list";
import Layout from "../components/layout";
import PortableText from "../components/portableText";
import SEO from "../components/seo";

export const query = graphql`
  query AboutPageQuery {
    about: allSanityAbout(limit: 1) {
      nodes {
        _rawText
      }
    }
  }
`;

const About = props => {
  const { data, errors } = props;
  const about = data.about.nodes[0];

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  return (
    <Layout>
      <SEO title="About Rebase" description={about._rawText[0]} />
      <Container>
        <div className="mx-auto" style={{ maxWidth: "60ch" }}>
          <PortableText blocks={about._rawText} />
        </div>
      </Container>
    </Layout>
  );
};

export default About;
